.mbox {
  margin: 10px;
  box-shadow: 0 2px 1px rgba(0, 0, 0, 0.09), 0 4px 2px rgba(0, 0, 0, 0.09),
    0 8px 4px rgba(0, 0, 0, 0.09), 0 16px 8px rgba(0, 0, 0, 0.09),
    0 32px 16px rgba(0, 0, 0, 0.09);
  border: inset 10px maroon;
  transition: all 1s;
}
.mbox:hover {
  border: solid 10px #faa630;
}
.mbox img {
  cursor: hand;
}
.mpsgallery {
  margin: 10px 10px 50px 10px;
  box-shadow: 0 2px 1px rgba(0, 0, 0, 0.09), 0 4px 2px rgba(0, 0, 0, 0.09),
    0 8px 4px rgba(0, 0, 0, 0.09), 0 16px 8px rgba(0, 0, 0, 0.09),
    0 32px 16px rgba(0, 0, 0, 0.09);
  border: inset 10px maroon;
  transition: all 1s;
  position: relative;
}
.mpsboxdetails{
  position: absolute;
  bottom:-20px;
  left:10%;
  background: blanchedalmond;
  width:80%;
  padding:10px 0;
  text-align: center;
  font:bold 16px Arial;
  color: maroon;
  box-shadow: 0 2px 1px rgba(0, 0, 0, 0.09), 0 4px 2px rgba(0, 0, 0, 0.09),
    0 8px 4px rgba(0, 0, 0, 0.09), 0 16px 8px rgba(0, 0, 0, 0.09),
    0 32px 16px rgba(0, 0, 0, 0.09);
}
.mpsboxdetails a:link, .mpsboxdetails a:visited{
  text-decoration: none;
  display: block;
}