.cbox{
    background: orange;
    padding:5%;
    margin:10px;
    width:90%;
    -webkit-box-shadow: 0 10px 6px -6px rgb(56, 56, 56);
	   -moz-box-shadow: 0 10px 6px -6px rgb(56, 56, 56);
	        box-shadow: 0 10px 6px -6px rgb(56, 56, 56);
}
.cbox:first-child{
    margin-top:0;
}

.cbox a:link, .cbox a:visited{
    color:maroon;
    text-decoration: none;
    transition: all 2s;
}
.cbox a:hover{
    text-decoration: underline;
}
.myClassname{
    margin-top:0px;
}