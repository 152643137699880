.satable{
    overflow-x:auto;
}
.satable td, .satable th{
    text-align: center !important;
}

td img{
    padding:10px;
    background: white;
    border:solid 1px rgb(61, 61, 61);
    border-radius: 5px;
}

.circlearea{
    display: flex;
    flex-direction: row !important;
    align-items: center;
    justify-content: center;
    padding:50px 0;
}
.circle{
    background:coral;
    padding:100px 0;
    width:200px;
    height:100px;
    margin:10px;
    text-align: center;
    font-weight: bold;
}