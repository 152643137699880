.about b {
  color: #882427;
}
.logoinbox{
  background-color: white;
}
.boxarea {
  background: #a12c2f linear-gradient(to top, maroon, rgb(155, 5, 5), maroon);
  padding: 70px 0;
}
.box {
  background: rgb(253, 170, 16)
    linear-gradient(rgb(253, 170, 16), rgb(233, 155, 10));
  border-radius: 10px;
  padding: 20px;
  margin: 20px 0px;
  cursor: pointer;
  transition: all 0.5s ease-out;
  text-align: center;
}
.box:hover {
  -webkit-box-shadow: 0 10px 6px -6px #000;
  -moz-box-shadow: 0 10px 6px -6px #000;
  box-shadow: 0 10px 6px -6px #000;
}
.boxarea h4,
.boxarea h5 {
  text-align: center;
  font-weight: bold;
  text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2),
    0px -5px 35px rgba(255, 255, 255, 0.3);
    font-family: "Lithos Pro Regular", Arial, Helvetica, sans-serif;
    margin-top:10px;
}
.boxarea a:link,
.boxarea a:visited {
  color: #202020;
  text-decoration: none;
}
.boxarea a:hover {
  color: #ae2014;
}

.notice {
  margin: 20px;
  background: #fff;
  padding: 20px 10px;
  max-height: 350px;
  border-radius: 10px;
}
.noticedata {
  overflow: auto;
  max-height: 280px;
}
.noticerow {
  padding: 10px 0;
  border-bottom: solid 1px rgb(212, 212, 212);
}
.notice h3 {
  overflow: hidden;
  text-align: center;
  color: #96260e;
  font-weight: bold;
  text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.3);
  font-family: "Lithos Pro Regular", Arial, Helvetica, sans-serif;
}
.notice h3:before,
.notice h3:after {
  background-color: #000;
  content: "";
  display: inline-block;
  height: 1px;
  position: relative;
  vertical-align: middle;
  width: 50%;
}
.notice h3:before {
  right: 0.5em;
  margin-left: -50%;
}
.notice h3:after {
  left: 0.5em;
  margin-right: -50%;
}
.notice ul {
  list-style: none;
}
.calendar {
  margin: 0 auto;
  padding-top: 5px;
  width: 60px;
  background: #ededef;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#ededef),
    to(#ccc)
  );
  background: -moz-linear-gradient(top, #ededef, #ccc);
  font: bold 15px/30px "Lucida Sans Unicode", Arial Black, Arial, Helvetica,
    sans-serif;
  text-align: center;
  color: #000;
  position: relative;
  text-shadow: #fff 0 1px 0;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  -moz-box-shadow: 0 2px 2px #888;
  -webkit-box-shadow: 0 2px 2px #888;
  box-shadow: 0 2px 2px #888;
}

.calendar em {
  display: block;
  font: 10px "Lucida Sans Unicode", Arial, Helvetica, sans-serif;
  color: #fff;
  text-shadow: #00365a 0 -1px 0;
  background: #04599a;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#04599a),
    to(#00365a)
  );
  background: -moz-linear-gradient(top, #04599a, #00365a);
  -moz-border-radius-bottomright: 3px;
  -webkit-border-bottom-right-radius: 3px;
  border-bottom-right-radius: 3px;
  -moz-border-radius-bottomleft: 3px;
  -webkit-border-bottom-left-radius: 3px;
  border-bottom-left-radius: 3px;
  border-top: 1px solid #00365a;
}

.calendar:before,
.calendar:after {
  content: "";
  float: left;
  position: absolute;
  top: 5px;
  width: 8px;
  height: 8px;
  background: #111;
  z-index: 1;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  -moz-box-shadow: 0 1px 1px #fff;
  -webkit-box-shadow: 0 1px 1px #fff;
  box-shadow: 0 1px 1px #fff;
}
.calendar:before {
  left: 11px;
}
.calendar:after {
  right: 11px;
}

.calendar em:before,
.calendar em:after {
  content: "";
  float: left;
  position: absolute;
  top: -5px;
  width: 4px;
  height: 14px;
  background: #dadada
    linear-gradient(linear, left top, left bottom, from(#f1f1f1), to(#aaa));
  background: -webkit-linear-gradient(
    linear,
    left top,
    left bottom,
    from(#f1f1f1),
    to(#aaa)
  );
  background: -moz-linear-gradient(top, #f1f1f1, #aaa);
  z-index: 2;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
}
.calendar em:before {
  left: 13px;
}
.calendar em:after {
  right: 13px;
}
.about {
  padding: 70px 25px;
  background: #fff url("../images/dust.png");
}
.about h3 {
  text-align: center !important;
  padding-bottom: 25px;
  margin-bottom: 25px;
  border-bottom: solid 1px #202020;
  font-weight: bold;
  text-shadow: #474747 1px 1px 2px;
  font-family: "Lithos Pro Regular", Arial, Helvetica, sans-serif;
}
.about p {
  text-align: justify;
}

.mps {
  background: #e98500 radial-gradient(orange, rgb(207, 139, 12));
  padding: 75px 25px;
}
.mpsbox {
  margin: 20px;
  position: relative;
  overflow: hidden;
  box-shadow: 2px 1px 30px -4px #555555;
  border: solid 10px #ffffff;
}
.mpsbox img {
  transition: all 2s;
}
.mpsbox:hover img {
  transform: scale(1.2, 1.2);
}
.mpsbox h3::first-letter {
  color: orange;
}
.mpsboxtxt {
  position: absolute;
  bottom: 0;
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.9));
  padding: 250px 0 30px 0;
  width: 100%;
}
.mpsboxtxt h3 {
  text-align: center;
  color: #fff;
  text-shadow: 2px 0 5px #000;
  font-weight: bolder;
  font-family: "Lithos Pro Regular", Arial, Helvetica, sans-serif;
}

.cca {
  background: #000;
  padding: 75px 25px;
}
.cca_transparent {
  background-color: rgba(225, 225, 225, 0.2);
  width: 100%;
  height: 100%;
  box-shadow: 0px 24px 24px rgba(0, 0, 0, 0.4);
  border: 1px solid rgba(225, 225, 225, 0.1);
  border-radius: 16px;
  padding: 20px 0;
}
.cca_transparent h3 {
  text-align: center;
  font-weight: bold;
  color: #FFF;
  
  font-family: "Lithos Pro Regular", Arial, Helvetica, sans-serif;
  text-shadow: 0px 4px 3px rgba(0,0,0,0.4),
             0px 8px 13px rgba(0,0,0,0.1),
             0px 18px 23px rgba(0,0,0,0.1);
}
.ccabox {
  display: inline-block;
  margin: 8px;
  background-color: rgba(225, 225, 225, 0.2);
  box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.3);
  border: 1px solid rgba(225, 225, 225, 0.1);
  border-radius: 16px;
  backdrop-filter: blur(10px);
  position: relative;
  padding: 10px;
}
.ccabox img{
  border-radius: 16px;
}
.details {
  position: absolute;
  padding: 10px 0;
  width: 90%;
  bottom: 10px;
  text-align: center;
  font-weight: bold;
  background: linear-gradient(to right, rgba(251, 173, 26,0) 10%,orange,rgba(251, 173, 26,0) 90%);
}

.toppers {
  background-image: linear-gradient(to bottom, #eb8312, #fba504, #f69a08, #f18e0e, #eb8312);
  padding: 75px 0;
}
.toppers h3{
  text-align: center;
  font-weight: bold;
  color: #AA2D18;
  padding-bottom: 25px;
  margin-bottom: 25px;
  border-bottom: solid 1px #202020;
  text-shadow: #474747 1px 1px 2px;
  font-family: "Lithos Pro Regular", Arial, Helvetica, sans-serif;
}
.per{
  text-align: center;
  font-weight: bold;
  padding: 10px 0;
}
.cca_transparent{
  width: 100%;
}
.topperbox{
  text-align: center;
  background-color: rgba(225, 225, 225, 0.2);
  box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.3);
  border: 1px solid rgba(225, 225, 225, 0.1);
  border-radius: 16px;
  backdrop-filter: blur(10px);
  padding: 10px;
}
.topperbox img{
  border-radius: 16px 16px 0 0;
}