.mpsimage{
    position: relative;
    margin-bottom: 50px;;
}
.mpsimage img{
    border:solid 5px #A12D2F;
    width:100%;
}
.mpsimagedetails{
    position: absolute;
    bottom:-20px;
    left:20px;
    width:80%;
    padding:10px 0;
    background: #A12D2F;
}
.mpsimagedetails h5,.mpsimagedetails h6{
    text-align: center;
}
.mpsimagedetails h5{
    color: orange;
}
.mpsimagedetails h6{
    color: white;
}
